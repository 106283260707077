export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    align: 'center',
    fixed: true,
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    fixed: true,
    minWidth: 150
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    minWidth: 110
  },
  {
    title: '端口编号',
    align: 'center',
    key: 'port'
  },
  {
    title: '油枪编号',
    align: 'center',
    key: 'gun_num'
  },
  {
    title: '监控微处理号',
    key: 'cpu_code',
    minWidth: 110
  },
  {
    title: '编码器号',
    key: 'code_num',
    minWidth: 100
  },
  {
    title: '防作弊状态',
    width: 120,
    align: 'center',
    key: 'status',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '获取时间',
    key: 'status_time',
    minWidth: 140
  },
  {
    title: '操作',
    width: 100,
    key: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action'
    }
  }
];

export const historyColumns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '加油机名称',
    key: 'tanker_name'
  },
  {
    title: '端口编号',
    key: 'port'
  },
  {
    title: '油枪编号',
    key: 'gun_num'
  },
  {
    title: '状态',
    key: 'status_msg',
    scopedSlots: {
      customRender: 'status_msg'
    }
  },
  {
    title: '获取时间',
    key: 'status_time',
    minWidth: 120
  }
];
