<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchParams.status"
            filterable
            :clearable="true"
            placeholder="防作弊状态"
          >
            <el-option value="0" label="未开启" />
            <el-option value="1" label="已开启" />
            <el-option value="2" label="64 锁机" />
            <el-option value="3" label="主板锁机" />
            <el-option value="4" label="默认值" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #status="{ scope }">
        <!-- <span :class="scope.row.status == 1 ? 'text-success' : 'text-danger'">{{
          statusMap[scope.row.status]
        }}</span> -->
        <div style="display: flex; justify-content: center;">
          <el-tag size="mini" :type="statusColor[scope.row.status]">
            {{ statusMap[scope.row.status] }}
          </el-tag>
        </div>
      </template>
      <template #action="{ scope }">
        <el-button type="primary" size="mini" @click="handleHistory(scope.row)"
          >历史记录</el-button
        >
      </template>
    </default-table>

    <!-- 历史记录 -->
    <el-dialog
      :title="siteName"
      :visible.sync="historyVisible"
      width="730px"
      custom-class="dialog"
      class="historyDialog"
      :close-on-click-modal="false"
    >
      <default-table
        :columns="historyColumns"
        :dataSource="historyData"
        :pagination="historyPagination"
        @currentChange="handleHistoryChange"
        :loading="historyTableLoading"
      >
        <template #index="{ scope }">{{ scope.$index + 1 }}</template>
        <template #status_msg="{ scope }">
          <span
            :class="scope.row.status == 1 ? 'text-success' : 'text-danger'"
            >{{ scope.row.status_msg }}</span
          >
        </template>
      </default-table>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import {
  QueryNoCheatList,
  ExportNoCheatList,
  QueryHistory
} from '@/api/no_cheat';
import { columns, historyColumns } from './columns.js';

import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      historyVisible: false,
      historyColumns,
      historyData: [],
      historyPagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      siteName: '',
      siteId: null,
      tanker_id: null,
      gun_num: null,
      historyTableLoading: true,

      tableLoading: true,
      gas: '',
      factory: '',
      searchParams: {
        site_id: '',
        status: ''
      },
      statusMap: ['未开启', '已开启', '64 锁机', '主板锁机', '默认值'],
      statusColor: [null, 'success', 'warning', 'danger', 'success'],
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  mixins: [mixins],
  created() {
    if (this.$route.query.status) this.searchParams.status = '0';
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryNoCheatList(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportNoCheatList(params)
        .then(res => {
          // const { code, msg } = res;
          // if (code !== 0) {
          //   this.$message({
          //     message: msg || '导出失败',
          //     type: 'error'
          //   });
          // }
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },

    handleHistoryChange(current) {
      this.historyPagination.current = current;
      this.handleHistoryQuery({
        current,
        // id: this.siteId
        tanker_id: this.tanker_id,
        gun_num: this.gun_num
      });
    },
    handleHistoryQuery({ current, tanker_id, gun_num }) {
      this.historyPagination.current = current || 1;
      const params = {
        page: this.historyPagination.current || 1,
        limit: this.historyPagination.pageSize,
        // id
        tanker_id,
        gun_num
      };
      QueryHistory(params).then(res => {
        if (res.code === 0) {
          this.historyData = res.data;
          this.historyPagination = {
            ...this.historyPagination,
            total: res.count
          };
          this.historyTableLoading = false;
        }
      });
    },
    handleHistory(row) {
      this.siteName = row.site_name;
      // this.siteId = row.id;
      this.tanker_id = row.tanker_id;
      this.gun_num = row.gun_num;
      this.handleHistoryQuery({
        current: 1,
        // id: row.id,
        tanker_id: row.tanker_id,
        gun_num: row.gun_num
      });
      this.historyVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
