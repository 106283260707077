import request from '@/utils/request';

export function QueryNoCheatList(params) {
  return request({
    url: '/prevent_cheat/data',
    method: 'get',
    params
  });
}

export function ExportNoCheatList(params) {
  return request({
    url: '/prevent_cheat/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function QueryHistory(params) {
  return request({
    url: '/prevent_cheat/get_history',
    method: 'get',
    params
  });
}
